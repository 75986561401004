/* Banner */

.banner-franchisee {
    background-image: url("../../../images/Banner/banner-franchisee.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: center center;
    content: "";
    text-align: left;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 45px;
}

.banner-franchisee-container {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.5rem;
    padding-right: 0.5rem;
}

.banner-franchisee-container .text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#home-franch .banner-franchisee-container .titulo-banner {
    font-size: 65px;
    text-shadow: 0px 0px 0px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .subtitulo-banner {
    font-size: 30px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .descricao-banner {
    font-size: 18px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .MuiTypography-h2 {
    font-size: 19px;
    margin-top: 10px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .button-banner {
    background: #ffaa49;
    color: #fff;
    margin-top: 10px;
}


/* Media Querys */

@media screen and (min-width: 650px) {
    /* Banner */
    #home-franch .banner-franchisee-container .button-banner {
        font-size: 20px;
        margin-top: 25px;
    }
    /* Título */
    #home-franch .banner-franchisee-container .MuiTypography-h1 {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    /* Banner */
    #home-franch .banner-franchisee-container .titulo-banner {
        text-align: center;
        font-size: 44px;
        font-size: 38px;
        width: 100%;
        text-shadow: 0px 0px 0px rgb(0 0 0);
        text-align: center;
    }
    #home-franch .banner-franchisee-container .subtitulo-banner {
        text-align: center;
        font-size: 24px;
        font-size: 20px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        text-shadow: 0px 0px 20px rgb(0 0 0);
    }
    #home-franch .banner-franchisee-container .button-banner {
        text-align: center;
        background: #ffaa49;
        color: #fff;
        margin-top: 10px;
        font-size: 15px;
    }
    .banner-franchisee-container {
        text-align: center;
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        text-align: center;
    }
    .banner-franchisee {
        margin-top: 30px;
    }
}