/* Formulário */

#cadastro-franqueado{
    background: white;
}

#cadastro-franqueado .formulario-cadastro-franqueado li{
    font-size: 18px;
}

#cadastro-franqueado .form-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    max-width: 600px;
    margin-top: 0px;
    background-color: white;
    font-weight: 400;
    color: black;
    padding: 0;
}

#cadastro-franqueado .formulario-cadastro-franqueado .MuiStepper-root {
    padding: 40px 20px;
}

#cadastro-franqueado .perguntas-frequentes-titulo {
    width: 100%;
    text-align: center;
    padding-top: 54px;
}

#cadastro-franqueado .perguntas-frequentes {
    width: 100%;
    text-align: center;
    padding-bottom: 70px;
    text-align: -webkit-center;
    background: #F2F2F2;
}

#cadastro-franqueado .makeStyles-root-15 {
    padding-right: 10px;
    padding-left: 10px;
}




/* Titulo */

#cadastro-franqueado .formulario-cadastro-franqueado h2 {
    font-family: 'Roboto';
    font-size: 3em;
    font-weight: 100;
    text-align: center;
}

#cadastro-franqueado .formulario-cadastro-franqueado h3 {
    font-family: 'Roboto';
    font-size: 2em;
    font-weight: 100;
    text-align: center;
}

#cadastro-franqueado .formulario-cadastro-franqueado .header-3 {
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 2rem;
    background-color: #F2F2F2;
    color: black;
    margin-bottom: 15px;
}
#cadastro-franqueado .formulario-cadastro-franqueado .makeStyles-heading-16 {
    font-size: 16.5px;
    font-family: 'Roboto';
    font-weight: 500;
    text-align: left;
}
#cadastro-franqueado .formulario-cadastro-franqueado .informacoes-relevantes {
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 15px;
    text-align: center;
    background-color: white;
}

#cadastro-franqueado .MuiTypography-body1 {
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400;
    text-align: left;
    text-indent: 1.5em;
}
/* Buttons */

#cadastro-franqueado .formulario-cadastro-franqueado .button-primary-form {
    margin-bottom: 5px;
    background-color: #393285;
    color: #ffffff;
    font-size: 0.9rem;
    text-align: center;
}

#dcadastro-franqueado .formulario-cadastro-franqueado .MuiChip-deleteIcon {
    color: #2E286A;
}

#cadastro-franqueado .formulario-cadastro-franqueado .MuiAutocomplete-tag {
    background-color: #393285;
}

#dcadastro-franqueado .formulario-cadastro-franqueado .MuiCheckbox-colorSecondary.Mui-checked, .MuiRadio-colorSecondary.Mui-checked {
    color: #FFAA49;
}

/* Texto Linha do Tempo */

#cadastro-franqueado .formulario-cadastro-franqueado .info {
    font-size: 0.9em;
}

/* Loading */

.loading {
    color: #393285;
}

/* Texto Linha do Tempo */

#cadastro-franqueado .formulario-cadastro-franqueado .formulario .MuiTypography-body2 {
    font-size: 0.75rem;
}

#cadastro-franqueado .formulario-cadastro-franqueado .WithStyles\(ForwardRef\(StepConnector\)\)-completed-20 .WithStyles\(ForwardRef\(StepConnector\)\)-line-21 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-intecadastro-franqueadorno .jss19 .jss20, #cadastro-franqueado .formulario-cadastro-franqueado .jss18 .jss20 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-cadastro-franqueado .WithStyles\(ForwardRef\(StepConnector\)\)-active-19 .WithStyles\(ForwardRef\(StepConnector\)\)-line-21 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-cadastro-franqueado .makeStyles-completed-17 {
    color: #5A559A;
}
#cadastro-franqueado .formulario-cadastro-franqueado .jss16 {
    color: #5A559A;
}
/* Alert */

#cadastro-franqueado .formulario-cadastro-franqueado .custom-info-alert {
    font-size: 1.2rem;
}

#cadastro-franqueado .formulario-cadastro-franqueado .custom-info-alert.MuiAlert-standardSuccess {
    color: #ffffff;
}

#cadastro-franqueado .formulario-cadastro-franqueado email-label {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 50;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 700px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiStepper-root {
        padding: 25px 0;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h2 {
        font-size: 2.3em;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h3 {
        font-size: 2.3em;
    }
}

@media screen and (max-width: 519px) {
    #cadastro-franqueado .formulario-cadastro-franqueado h2 {
        font-size: 1.8em;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h3 {
        font-size: 1.8em;
    }
}

@media screen and (max-width: 387px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiTypography-body2 {
        font-size: 0.75rem;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado .btn-solicite {
        font-size: 1.2rem;
    }
}

@media (min-width: 600px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiGrid-grid-sm-4 {
        padding: 16px;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado .btn-solicite {
        font-size: 1.3rem;
    }
}