/* Footer */

.footer {
    height: auto;
    padding: 70px 20px;
}

.footer-interno {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.sobre-reparofone, .info-contatos {
    width: 30%;
}

/* Sobre a Reparofone */

.texto-sobre, .texto-cnpj {
    font-size: 1.3em;
}

/* Informações de Contato */

#footer-franch .footer-interno h3.ui.header {
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 30px;
}

.ui.animated.list>.item {
    padding: 10px;
    font-size: 1.3em;
}

.ui.list .list>.item a, .ui.list>.item a {
    color: #333333;
}

/* Copyright */

.copyright-franch {
    width: 100%;
    background: #3c3c3c;
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    font-family: 'Roboto';
    font-weight: 100;
}

/* Whatsapp Pulse */

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}

.wc_whatsapp_app {
    position: fixed;
    bottom: 30px;
    z-index: 9999999999;
    display: flex;
    align-items: center;
}

.wc_whatsapp_app:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    background: #25d366;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.wc_whatsapp_app.left {
    left: 30px;
}

.wc_whatsapp_app.right {
    right: 30px;
}

.wc_whatsapp {
    z-index: 10;
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
    background: #25d366;
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
}

.wc_whatsapp:hover, .wc_whatsapp:focus {
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    background: #179345;
}

.wc_whatsapp::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' data-prefix='icon' data-icon='whatsapp' class='svg-inline' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23fff' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'%3e%3c/path%3e%3c/svg%3e") top center no-repeat;
    height: 30px;
    margin-top: 15px;
}

/* Media Querys */

@media screen and (max-width: 700px) {
    /* Whatsapp Pulse */
    .wc_whatsapp_app {
        bottom: 70px;
    }
}

@media screen and (max-width: 600px) {
    /* Footer */
    .sobre-reparofone, .info-contatos {
        width: 100%;
    }
    .sobre-reparofone {
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 400px) {
    /* Footer */
    #footer-franch .footer-interno h3.ui.header {
        font-size: 1.5em;
    }
    #footer-franch .footer-interno h3.ui.animated.list>.item {
        font-size: 1.2em;
    }
    .texto-sobre, .texto-cnpj {
        font-size: 1.2em;
    }
}