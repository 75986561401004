/* Como Funciona */

.como-funciona-franchisee {
	/* background-image: url("../../../../images/como-funciona.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	background-position: center center;
	content: "";
	text-align: left;
	color: black;
	background: #F2F2F2;
}

.slick-prev:before, .slick-next:before {
	color: #4f4f50 !important;
}

.paragraphs {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.como-funciona-franchisee-cards {
	margin: 0 auto;
	max-width: 1200px;
	padding-bottom: 70px;
	padding: 70px 0;
}

.como-funciona-franchisee-cards-intern {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 0px;
}

#how-it-works-franch .como-funciona-franchisee-cards h2.ui.header {
	text-align: left;
	font-size: 15px;
}

/* Cards */

#how-it-works-franch .como-funciona-franchisee-cards .ui.card {
	margin: 10px;
}

#how-it-works-franch .como-funciona-franchisee-cards .image-card {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	background: rgba(0, 0, 0, .05);
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card, .ui.cards>.card {
	width: 200px;
	min-height: 359px;
}

#how-it-works-franch .como-funciona-franchisee-cards .image-card img {
	width: 50%;
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card>.content, .ui.cards>.card>.content {
	font-size: 1.1em;
	min-height: 130px;
	padding-left: 10px;
	padding-top: 15px;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
	text-transform: uppercase;
	padding: 15px 0;
	font-weight: 500;
	font-size: 1.28571429em;
	margin-top: -0.21425em;
	line-height: 1.28571429em;
}

.ui.card>.extra, .ui.cards>.card>.extra {
	max-width: 100%;
	min-height: 0 !important;
	flex-grow: 0;
	border-top: 1px solid rgba(0, 0, 0, .05) !important;
	position: static;
	background: 0 0;
	width: auto;
	margin: 0;
	padding: 0.75em 1em;
	top: 0;
	left: 0;
	color: rgba(0, 0, 0, .4);
	box-shadow: none;
	transition: color .1s ease;
}

i.icon {
	display: inline-block;
	opacity: 1;
	margin: 0 0.25rem 0 0;
	width: 1.18em;
	height: 1em;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	text-decoration: inherit;
	text-align: center;
	speak: none;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

i.icon, i.icons {
	font-size: 1em;
}

.ui.header {
	width: 100%;
}

#how-it-works-franch .como-funciona-franchisee-cards .descricao-funcionamento {
	font-size: 20px;
	font-family: 'Roboto';
	font-weight: 100;
	padding-left: 10px;
	padding-right: 15px;
	text-indent: 1.5em;
}

#how-it-works-franch .como-funciona-franchisee-cards .subdescricao-funcionamento {
	font-size: 20px;
	text-indent: 1.5em;
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card {
	background-color: white;
	text-align: left;
	border: 2.5px solid rgb(204, 204, 204);
	border-radius: 4px;
	font-family: 'Roboto';
}

/* Informações Adicionais */

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph {
	color: #717171;
	width: 90%;
}

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph.p-1 {
	margin-top: 10px;
}

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph.p-2 {
	margin-bottom: 30px;
}

/* Button Orçamento */

#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
	background: #FFAA49;
	color: #ffffff;
}

/* Section Orçamento */

#how-it-works-franch .como-funciona-franchisee-cards .section-orcamento {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

#how-it-works-franch .return-text {
	text-align: center;
}

/* Media Querys */

@media screen and (max-width: 600px) {

	/* Como Funciona */
	.como-funciona-franchisee-cards {
		text-align: center;
	}

	#how-it-works-franch .como-funciona-franchisee-cards h2.ui.header {
		font-size: 2em;
		text-align: center;
		padding: 0 50px;
	}
}

@media screen and (min-width: 440px) {

	/* Button Orçamento */
	#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
		font-size: 1.7rem;
		margin-top: 25px;
	}
}

@media screen and (min-width: 390px) and (max-width: 439px) {

	/* Button Orçamento */
	#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
		font-size: 1.3rem;
	}
}