@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
.slide-enter,
.slide-exit {
  opacity: 0.4;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}

.slide-enter {
  opacity: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.slide-exit {
  opacity: ;
  position: absolute;
  top: calc(100% - 90%);
  left: 0;
  width: 100%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.slide-exit-active {
  opacity: 0.5;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

body {
  margin: 0;
  margin-bottom: 5rem;
  background: #f0f0f0;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
}

.ReactModal__Overlay {
  -webkit-transform: translateY(calc(100% - 10px));
          transform: translateY(calc(100% - 10px));
  transition: all 350ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  -webkit-transform: translateY(calc(100% - 10px));
          transform: translateY(calc(100% - 10px));
}
/* Header */

.header-top {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1200px;
}

/* Menu Mobile */

#header-top .MuiBottomNavigation-root {
	background-color: #F2F2F2;
}

.MuiToolbar-root {
	display: flex;
	justify-content: space-between;
}

#header-top .MuiAppBar-colorPrimary {
	color: #fff;
	background-color: #ffffff;
}

#header-top .makeStyles-root-1 {
	width: 100%;
	align-items: center;
}

#header-top .jss1 {
	width: 100%;
}

.mobile-menu {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 999999;
}

/* Menu */

.menu {
	color: #333333;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button-menu {
	padding: 0 10px;
	text-decoration: none;
	color: #333333;
	font-size: 16px;
	overflow: hidden;
	outline: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
	text-shadow: 0 0 1px rgb(255 255 255 / 30%);
	transition: all .5s;
}

.button-menu:hover, .button-menu:focus {
	color: #393285;
}

#header-top .ui.button {
	text-transform: uppercase;
}

#header-top .button-entrar button {
	background: #393285;
	color: #ffffff;
}

#header-top .button-seja-franqueado button {
	background: #F2F2F2;
	color: #333333;
	margin-right: 10px;
}


#header-top .button-orcamento button {
	background: #FFAA49;
	color: #ffffff;
	margin-right: 10px;
}

#header-top .button-menu button i {
	background: #100e254f;
}

/* Logo */

.logo-link {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Media Querys */

@media screen and (min-width: 600px) {
	/* Menu */
	.button-menu:before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: #393285;
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	.button-menu:hover:before, .button-menu:focus:before {
		-webkit-transform: scaleX(.85);
		        transform: scaleX(.85);
	}
	.button-menu:before, .button-menu:after {
		content: "";
		position: absolute;
		transition: -webkit-transform 0.5s ease;
		transition: transform 0.5s ease;
		transition: transform 0.5s ease, -webkit-transform 0.5s ease;
	}
	button:before {
		content: none;
	}
	a, a>span {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
	}
}

@media screen and (max-width: 400px) {
	/* Logo */
	.logo {
		display: flex;
	}
	.logo img {
		width: 170px;
	}
	#header-top .button-entrar button {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 380px) {
	#header-top .MuiBottomNavigationAction-root {
		min-width: 60px;
	}
	.MuiBottomNavigationAction-label {
		text-align: center;
	}
	#header-top .MuiBottomNavigationAction-label.Mui-selected {
		font-size: 0.875rem;
	}
	#header-top .MuiBottomNavigationAction-label {
		font-size: 0.7rem;
	}
}
/* Banner */

.banner {
    background-image: url(/static/media/reparando-smartphone-dark.73144335.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: center center;
    content: "";
    text-align: left;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
}

.banner-container {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.5rem;
    padding-right: 0.5rem;
}

.banner-container .text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#home .banner-container .MuiTypography-h1 {
    font-size: 35px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home .banner-container .MuiTypography-h2 {
    font-size: 18px;
    margin-top: 10px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home .banner-container .button-banner {
    background: #FFAA49;
    color: #ffffff;
    margin-top: 10px;
}

/* Media Querys */

@media screen and (min-width: 600px) and (max-width: 800px) {
    /* Banner */
    #home .banner-container .MuiTypography-h1  {
        font-size: 30px;
        width: 90%;
    }
}

@media screen and (min-width: 440px) {
    /* Banner */
    #home .banner-container .button-banner {
        font-size: 1.7rem;
        margin-top: 25px;
    }

    /* Título */
    #home .banner-container .MuiTypography-h1 {
        width: 70%;
    }
}

@media screen and (min-width: 390px) and (max-width: 439px) {
    /* Banner */
    #home .banner-container .button-banner {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 600px) {
    /* Banner */
    #home .banner-container .MuiTypography-h1  {
        font-size: 26px;
        width: 100%;
        text-align: center;
    }
    #home .banner-container .MuiTypography-h2 {
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .banner-container {
        text-align: center;
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .banner {
        margin-top: 30px;
    }
}
/* Como Funciona */

.como-funciona {
	/* background-image: url("../../../../images/como-funciona.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	background-position: center center;
	content: "";
	text-align: left;
	color: white;
}

.slick-prev:before, .slick-next:before {
	color: #4f4f50 !important;
}

.depoimentos {
	display: flex;
}

.paragraphs {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.como-funciona-cards {
	margin: 0 auto;
	max-width: 1200px;
	padding: 70px 0;
}

.como-funciona-cards-intern {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

/* Cards */

#how-it-works .como-funciona-cards .ui.card {
	margin: 10px;
}

#how-it-works .como-funciona-cards .image-card {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

#how-it-works .como-funciona-cards .ui.card, .ui.cards>.card {
	width: 200px;
	min-height: 359px;
}

#how-it-works .como-funciona-cards .image-card img {
	width: 50%;
}

#how-it-works .como-funciona-cards .ui.card>.content, .ui.cards>.card>.content {
	font-size: 1.1em;
	min-height: 130px;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
	text-transform: uppercase;
	padding: 15px 0;
}

.ui.header {
	width: 100%;
}

#how-it-works .como-funciona-cards h2.ui.header {
	font-size: 3em;
	font-family: 'Roboto';
	font-weight: 100;
	padding: 0 40px;
}

/* Informações Adicionais */

#how-it-works .como-funciona-cards .info-paragraph {
	color: #717171;
	width: 90%;
}

#how-it-works .como-funciona-cards .info-paragraph.p-1 {
	margin-top: 30px;
}

#how-it-works .como-funciona-cards .info-paragraph.p-2 {
	margin-bottom: 30px;
}

/* Button Orçamento */

#how-it-works .como-funciona-cards .button-cards {
	background: #FFAA49;
	color: #ffffff;
}

/* Section Orçamento */

#how-it-works .como-funciona-cards .section-orcamento {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

/* Media Querys */

@media screen and (max-width: 600px) {
	/* Como Funciona */
	.como-funciona-cards {
		text-align: center;
	}
	#how-it-works .como-funciona-cards h2.ui.header {
		font-size: 2em;
		text-align: center;
		padding: 0 50px;
	}
}
@media screen and (max-width: 960px) {
	/* Como Funciona */
	.depoimentos {
		flex-direction: column;
	}
}

@media screen and (min-width: 440px) {
	/* Button Orçamento */
	#how-it-works .como-funciona-cards .button-cards {
		font-size: 1.7rem;
		margin-top: 25px;
	}
}

@media screen and (min-width: 390px) and (max-width: 439px) {
	/* Button Orçamento */
	#how-it-works .como-funciona-cards .button-cards {
		font-size: 1.3rem;
	}
}
/* Footer */

.footer {
    height: auto;
    padding: 70px 20px;
}

.footer-interno {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.sobre-reparofone, .info-contatos, .footer-institucional {
    width: 30%;
}

/* Sobre a Reparofone */

.texto-sobre, .texto-cnpj {
    font-size: 1.3em;
}

/* Informações de Contato */

#footer .footer-interno h3.ui.header {
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 30px;
}

.ui.animated.list>.item {
    padding: 10px;
    font-size: 1.3em;
}

.ui.list .list>.item a, .ui.list>.item a {
    color: #333333;
}

/* Copyright */

.copyright {
    width: 100%;
    background: #3c3c3c;
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    font-family: 'Roboto';
    font-weight: 100;
}

/* Whatsapp Pulse */

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}

.wc_whatsapp_app {
    position: fixed;
    bottom: 30px;
    z-index: 9999999999;
    display: flex;
    align-items: center;
}

.wc_whatsapp_app:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    background: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
            animation: pulse-border 1500ms ease-out infinite;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.wc_whatsapp_app.left {
    left: 30px;
}

.wc_whatsapp_app.right {
    right: 30px;
}

.wc_whatsapp {
    z-index: 10;
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
    background: #25d366;
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
}

.wc_whatsapp:hover, .wc_whatsapp:focus {
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    background: #179345;
}

.wc_whatsapp::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' data-prefix='icon' data-icon='whatsapp' class='svg-inline' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23fff' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'%3e%3c/path%3e%3c/svg%3e") top center no-repeat;
    height: 30px;
    margin-top: 15px;
}

/* Media Querys */

@media screen and (max-width: 700px) {
    /* Copyright */
    .copyright {
        padding-bottom: 70px;
    }
    /* Whatsapp Pulse */
    .wc_whatsapp_app {
        bottom: 70px;
    }
}

@media screen and (max-width: 600px) {
    /* Footer */
    .footer-institucional {
        margin-bottom: 40px;
    }
    .footer-institucional  .ui.animated.list>.item{
       padding-left: 0;
    }
    .footer-institucional, .sobre-reparofone, .info-contatos {
        width: 100%;
    }
    .sobre-reparofone {
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 400px) {
    /* Footer */
    #footer .footer-interno h3.ui.header {
        font-size: 1.5em;
    }
    #footer .footer-interno h3.ui.animated.list>.item {
        font-size: 1.2em;
    }
    .texto-sobre, .texto-cnpj {
        font-size: 1.2em;
    }
}
.servicos {
    padding: 70px 0;
    background: #F2F2F2;
}

.servicos-interno {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

/* Lista */

#services .servicos-interno .MuiListItem-root {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lista-servicos {
    margin: 10px 10px;
    width: 22%;
    border: 1px solid #ccc;
    border-radius: 20px;
}

.root {
    color: #FFAA49;
    background-color: #ffffff;
    border-radius: 20px;
    min-height: 178px;
}

/* Titulo */

.titulo {
    width: 100%
}

#services .servicos-interno .titulo h2 {
    font-family: 'Roboto';
    font-size: 3em;
    font-weight: 100;
}

/* Texto Lista */

#services .servicos-interno .MuiTypography-colorTextSecondary {
    color: #3C3C3C;
    padding-top: 10px;
}

/* Título Lista */

#services .servicos-interno .MuiTypography-body1 {
    font-weight: 600;
    text-transform: uppercase;
}

.inline {
    display: 'inline';
}

/* Ícone */

#services .servicos-interno .MuiAvatar-root {
    border-radius: 0;
}

.MuiListItemAvatar-alignItemsFlexStart {
    justify-content: center;
    align-items: center;
    display: flex;
}

/* Button Orçamento */

#services .servicos-interno .section-orcamento .button-services {
    background: #FFAA49;
    color: #ffffff;
}

/* Section Orçamento */

#services .servicos-interno .section-orcamento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

#services .servicos-interno .MuiDivider-root {
    background-color: #3C3C3C;
}

.MuiListItem-gutters {
    min-height: 158px;
}

@media screen and (min-width: 800px) {
    #services .servicos-interno .titulo h2 {
        padding: 0 0 30px 40px;
    }
}

@media screen and (max-width: 800px) {
    .lista-servicos {
        width: 28%;
    }
    #services .servicos-interno .titulo h2 {
        text-align: center;
        padding-bottom: 30px;
        font-size: 2em;
    }
}

@media screen and (max-width: 550px) {
    .lista-servicos {
        width: 40%;
    }
}

@media screen and (max-width: 430px) {
    .lista-servicos {
        width: 80%;
    }
}

@media screen and (min-width: 440px) {
    /* Button Orçamento */
    #services .servicos-interno .section-orcamento .button-services {
        font-size: 1.7rem;
        margin-top: 25px;
    }
}

@media screen and (min-width: 390px) and (max-width: 439px) {
    /* Button Orçamento */
    #services .servicos-interno .section-orcamento .button-services {
        font-size: 1.3rem;
    }
}
/* Whatsapp Pulse */

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}

.wc_whatsapp_app {
    position: fixed;
    bottom: 30px;
    z-index: 9999999999;
    display: flex;
    align-items: center;
}

.wc_whatsapp_app:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    background: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
            animation: pulse-border 1500ms ease-out infinite;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.wc_whatsapp_app.left {
    left: 30px;
}

.wc_whatsapp_app.right {
    right: 30px;
}

.wc_whatsapp {
    z-index: 10;
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
    background: #25d366;
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
}

.wc_whatsapp:hover, .wc_whatsapp:focus {
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    background: #179345;
}

.wc_whatsapp::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' data-prefix='icon' data-icon='whatsapp' class='svg-inline' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23fff' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'%3e%3c/path%3e%3c/svg%3e") top center no-repeat;
    height: 30px;
    margin-top: 15px;
}

/* Media Querys */
@media screen and (max-width: 700px) {
    /* Whatsapp Pulse */
    .wc_whatsapp_app {
        bottom: 70px;
    }
}
/* Formulário */

#cadastro-franqueado{
    background: white;
}

#cadastro-franqueado .formulario-cadastro-franqueado li{
    font-size: 18px;
}

#cadastro-franqueado .form-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    max-width: 600px;
    margin-top: 0px;
    background-color: white;
    font-weight: 400;
    color: black;
    padding: 0;
}

#cadastro-franqueado .formulario-cadastro-franqueado .MuiStepper-root {
    padding: 40px 20px;
}

#cadastro-franqueado .perguntas-frequentes-titulo {
    width: 100%;
    text-align: center;
    padding-top: 54px;
}

#cadastro-franqueado .perguntas-frequentes {
    width: 100%;
    text-align: center;
    padding-bottom: 70px;
    text-align: -webkit-center;
    background: #F2F2F2;
}

#cadastro-franqueado .makeStyles-root-15 {
    padding-right: 10px;
    padding-left: 10px;
}




/* Titulo */

#cadastro-franqueado .formulario-cadastro-franqueado h2 {
    font-family: 'Roboto';
    font-size: 3em;
    font-weight: 100;
    text-align: center;
}

#cadastro-franqueado .formulario-cadastro-franqueado h3 {
    font-family: 'Roboto';
    font-size: 2em;
    font-weight: 100;
    text-align: center;
}

#cadastro-franqueado .formulario-cadastro-franqueado .header-3 {
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 2rem;
    background-color: #F2F2F2;
    color: black;
    margin-bottom: 15px;
}
#cadastro-franqueado .formulario-cadastro-franqueado .makeStyles-heading-16 {
    font-size: 16.5px;
    font-family: 'Roboto';
    font-weight: 500;
    text-align: left;
}
#cadastro-franqueado .formulario-cadastro-franqueado .informacoes-relevantes {
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 15px;
    text-align: center;
    background-color: white;
}

#cadastro-franqueado .MuiTypography-body1 {
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400;
    text-align: left;
    text-indent: 1.5em;
}
/* Buttons */

#cadastro-franqueado .formulario-cadastro-franqueado .button-primary-form {
    margin-bottom: 5px;
    background-color: #393285;
    color: #ffffff;
    font-size: 0.9rem;
    text-align: center;
}

#dcadastro-franqueado .formulario-cadastro-franqueado .MuiChip-deleteIcon {
    color: #2E286A;
}

#cadastro-franqueado .formulario-cadastro-franqueado .MuiAutocomplete-tag {
    background-color: #393285;
}

#dcadastro-franqueado .formulario-cadastro-franqueado .MuiCheckbox-colorSecondary.Mui-checked, .MuiRadio-colorSecondary.Mui-checked {
    color: #FFAA49;
}

/* Texto Linha do Tempo */

#cadastro-franqueado .formulario-cadastro-franqueado .info {
    font-size: 0.9em;
}

/* Loading */

.loading {
    color: #393285;
}

/* Texto Linha do Tempo */

#cadastro-franqueado .formulario-cadastro-franqueado .formulario .MuiTypography-body2 {
    font-size: 0.75rem;
}

#cadastro-franqueado .formulario-cadastro-franqueado .WithStyles\(ForwardRef\(StepConnector\)\)-completed-20 .WithStyles\(ForwardRef\(StepConnector\)\)-line-21 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-intecadastro-franqueadorno .jss19 .jss20, #cadastro-franqueado .formulario-cadastro-franqueado .jss18 .jss20 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-cadastro-franqueado .WithStyles\(ForwardRef\(StepConnector\)\)-active-19 .WithStyles\(ForwardRef\(StepConnector\)\)-line-21 {
    border-color: #393285;
}

#cadastro-franqueado .formulario-cadastro-franqueado .makeStyles-completed-17 {
    color: #5A559A;
}
#cadastro-franqueado .formulario-cadastro-franqueado .jss16 {
    color: #5A559A;
}
/* Alert */

#cadastro-franqueado .formulario-cadastro-franqueado .custom-info-alert {
    font-size: 1.2rem;
}

#cadastro-franqueado .formulario-cadastro-franqueado .custom-info-alert.MuiAlert-standardSuccess {
    color: #ffffff;
}

#cadastro-franqueado .formulario-cadastro-franqueado email-label {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 50;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 700px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiStepper-root {
        padding: 25px 0;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h2 {
        font-size: 2.3em;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h3 {
        font-size: 2.3em;
    }
}

@media screen and (max-width: 519px) {
    #cadastro-franqueado .formulario-cadastro-franqueado h2 {
        font-size: 1.8em;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado h3 {
        font-size: 1.8em;
    }
}

@media screen and (max-width: 387px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiTypography-body2 {
        font-size: 0.75rem;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado .btn-solicite {
        font-size: 1.2rem;
    }
}

@media (min-width: 600px) {
    #cadastro-franqueado .formulario-cadastro-franqueado .MuiGrid-grid-sm-4 {
        padding: 16px;
    }
    #cadastro-franqueado .formulario-cadastro-franqueado .btn-solicite {
        font-size: 1.3rem;
    }
}
/* Header */

.header-top {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1200px;
}

#header-top .button-entrar-franch button {
	background: #393285;
	color: #ffffff;
}

/* Menu Mobile */

#header-top .MuiBottomNavigation-root {
	background-color: #F2F2F2;
}

.MuiToolbar-root {
	display: flex;
	justify-content: space-between;
}

#header-top .MuiAppBar-colorPrimary {
	color: #fff;
	background-color: #ffffff;
}

#header-top .makeStyles-root-1 {
	width: 100%;
	align-items: center;
}

#header-top .jss1 {
	width: 100%;
}

.mobile-menu {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 999999;
}

/* Menu */

#header-top .ui.button {
	text-transform: uppercase;
}

/* Logo */

.logo-franchisee-link {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Media Querys */

@media screen and (min-width: 600px) {
	/* Menu */
	.button-menu:before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: #393285;
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
	}
	.button-menu:hover:before, .button-menu:focus:before {
		-webkit-transform: scaleX(.85);
		        transform: scaleX(.85);
	}
	.button-menu:before, .button-menu:after {
		content: "";
		position: absolute;
		transition: -webkit-transform 0.5s ease;
		transition: transform 0.5s ease;
		transition: transform 0.5s ease, -webkit-transform 0.5s ease;
	}
	button:before {
		content: none;
	}
	a, a>span {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
	}
}

@media screen and (max-width: 465px) {
	/* Logo */
	.logo-franchisee {
		display: flex;
	}
	.logo-franchisee img {
		width: 170px;
	}
	#header-top .button-entrar-franch button {
		font-size: 10px;
	}
	#header-top .button-orcamento button {
		font-size: 10px;
	}
}

@media screen and (max-width: 340px) {
	/* Logo */
	.logo-franchisee {
		display: flex;
	}
	.logo-franchisee img {
		width: 150px;
	}
	#header-top .button-entrar-franch button {
		font-size: 8px;
		display: flex;
	}
	#header-top .button-orcamento button {
		font-size: 8px;
		display: flex;
	}
}


@media screen and (max-width: 605px) {
	#header-top .MuiButton-root {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
		font-size: 8px;
    padding-right: 10px;
    padding-left: 10px;
		text-align: center;
	}
}
@media screen and (max-width: 475px) {
	#header-top .MuiButton-root {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
    padding-right: 10px;
    padding-left: 10px;
		text-align: center;
	}
}

@media screen and (max-width: 600px) {
	.logo-franchisee img {
		width: 150px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 12px;
	}
	#header-top .button-orcamento button {
		font-size: 12px;
	}
}

@media screen and (max-width: 465px) {
	.logo-franchisee img {
		width: 130px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 9px;
	}
	#header-top .button-orcamento button {
		font-size: 9px;
	}
}

@media screen and (max-width: 375px) {
	.logo-franchisee img {
		width: 110px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 8px;
	}
	#header-top .button-orcamento button {
		font-size: 8px;
	}
}
/* Footer */

.footer {
    height: auto;
    padding: 70px 20px;
}

.footer-interno {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.sobre-reparofone, .info-contatos {
    width: 30%;
}

/* Sobre a Reparofone */

.texto-sobre, .texto-cnpj {
    font-size: 1.3em;
}

/* Informações de Contato */

#footer-franch .footer-interno h3.ui.header {
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 30px;
}

.ui.animated.list>.item {
    padding: 10px;
    font-size: 1.3em;
}

.ui.list .list>.item a, .ui.list>.item a {
    color: #333333;
}

/* Copyright */

.copyright-franch {
    width: 100%;
    background: #3c3c3c;
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    font-family: 'Roboto';
    font-weight: 100;
}

/* Whatsapp Pulse */

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}

.wc_whatsapp_app {
    position: fixed;
    bottom: 30px;
    z-index: 9999999999;
    display: flex;
    align-items: center;
}

.wc_whatsapp_app:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    background: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
            animation: pulse-border 1500ms ease-out infinite;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.wc_whatsapp_app.left {
    left: 30px;
}

.wc_whatsapp_app.right {
    right: 30px;
}

.wc_whatsapp {
    z-index: 10;
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
    background: #25d366;
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
}

.wc_whatsapp:hover, .wc_whatsapp:focus {
    box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.2);
    background: #179345;
}

.wc_whatsapp::before {
    content: "";
    display: block;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' focusable='false' data-prefix='icon' data-icon='whatsapp' class='svg-inline' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23fff' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'%3e%3c/path%3e%3c/svg%3e") top center no-repeat;
    height: 30px;
    margin-top: 15px;
}

/* Media Querys */

@media screen and (max-width: 700px) {
    /* Whatsapp Pulse */
    .wc_whatsapp_app {
        bottom: 70px;
    }
}

@media screen and (max-width: 600px) {
    /* Footer */
    .sobre-reparofone, .info-contatos {
        width: 100%;
    }
    .sobre-reparofone {
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 400px) {
    /* Footer */
    #footer-franch .footer-interno h3.ui.header {
        font-size: 1.5em;
    }
    #footer-franch .footer-interno h3.ui.animated.list>.item {
        font-size: 1.2em;
    }
    .texto-sobre, .texto-cnpj {
        font-size: 1.2em;
    }
}
/* Banner */

.banner-franchisee {
    background-image: url(/static/media/banner-franchisee.dcaba36a.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: center center;
    content: "";
    text-align: left;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 45px;
}

.banner-franchisee-container {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.5rem;
    padding-right: 0.5rem;
}

.banner-franchisee-container .text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#home-franch .banner-franchisee-container .titulo-banner {
    font-size: 65px;
    text-shadow: 0px 0px 0px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .subtitulo-banner {
    font-size: 30px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .descricao-banner {
    font-size: 18px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .MuiTypography-h2 {
    font-size: 19px;
    margin-top: 10px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home-franch .banner-franchisee-container .button-banner {
    background: #ffaa49;
    color: #fff;
    margin-top: 10px;
}


/* Media Querys */

@media screen and (min-width: 650px) {
    /* Banner */
    #home-franch .banner-franchisee-container .button-banner {
        font-size: 20px;
        margin-top: 25px;
    }
    /* Título */
    #home-franch .banner-franchisee-container .MuiTypography-h1 {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    /* Banner */
    #home-franch .banner-franchisee-container .titulo-banner {
        text-align: center;
        font-size: 44px;
        font-size: 38px;
        width: 100%;
        text-shadow: 0px 0px 0px rgb(0 0 0);
        text-align: center;
    }
    #home-franch .banner-franchisee-container .subtitulo-banner {
        text-align: center;
        font-size: 24px;
        font-size: 20px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        text-shadow: 0px 0px 20px rgb(0 0 0);
    }
    #home-franch .banner-franchisee-container .button-banner {
        text-align: center;
        background: #ffaa49;
        color: #fff;
        margin-top: 10px;
        font-size: 15px;
    }
    .banner-franchisee-container {
        text-align: center;
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        text-align: center;
    }
    .banner-franchisee {
        margin-top: 30px;
    }
}
/* Como Funciona */

.como-funciona-franchisee {
	/* background-image: url("../../../../images/como-funciona.png"); */
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	background-position: center center;
	content: "";
	text-align: left;
	color: black;
	background: #F2F2F2;
}

.slick-prev:before, .slick-next:before {
	color: #4f4f50 !important;
}

.paragraphs {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.como-funciona-franchisee-cards {
	margin: 0 auto;
	max-width: 1200px;
	padding-bottom: 70px;
	padding: 70px 0;
}

.como-funciona-franchisee-cards-intern {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 0px;
}

#how-it-works-franch .como-funciona-franchisee-cards h2.ui.header {
	text-align: left;
	font-size: 15px;
}

/* Cards */

#how-it-works-franch .como-funciona-franchisee-cards .ui.card {
	margin: 10px;
}

#how-it-works-franch .como-funciona-franchisee-cards .image-card {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	background: rgba(0, 0, 0, .05);
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card, .ui.cards>.card {
	width: 200px;
	min-height: 359px;
}

#how-it-works-franch .como-funciona-franchisee-cards .image-card img {
	width: 50%;
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card>.content, .ui.cards>.card>.content {
	font-size: 1.1em;
	min-height: 130px;
	padding-left: 10px;
	padding-top: 15px;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
	text-transform: uppercase;
	padding: 15px 0;
	font-weight: 500;
	font-size: 1.28571429em;
	margin-top: -0.21425em;
	line-height: 1.28571429em;
}

.ui.card>.extra, .ui.cards>.card>.extra {
	max-width: 100%;
	min-height: 0 !important;
	flex-grow: 0;
	border-top: 1px solid rgba(0, 0, 0, .05) !important;
	position: static;
	background: 0 0;
	width: auto;
	margin: 0;
	padding: 0.75em 1em;
	top: 0;
	left: 0;
	color: rgba(0, 0, 0, .4);
	box-shadow: none;
	transition: color .1s ease;
}

i.icon {
	display: inline-block;
	opacity: 1;
	margin: 0 0.25rem 0 0;
	width: 1.18em;
	height: 1em;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	text-decoration: inherit;
	text-align: center;
	speak: none;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

i.icon, i.icons {
	font-size: 1em;
}

.ui.header {
	width: 100%;
}

#how-it-works-franch .como-funciona-franchisee-cards .descricao-funcionamento {
	font-size: 20px;
	font-family: 'Roboto';
	font-weight: 100;
	padding-left: 10px;
	padding-right: 15px;
	text-indent: 1.5em;
}

#how-it-works-franch .como-funciona-franchisee-cards .subdescricao-funcionamento {
	font-size: 20px;
	text-indent: 1.5em;
}

#how-it-works-franch .como-funciona-franchisee-cards .ui.card {
	background-color: white;
	text-align: left;
	border: 2.5px solid rgb(204, 204, 204);
	border-radius: 4px;
	font-family: 'Roboto';
}

/* Informações Adicionais */

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph {
	color: #717171;
	width: 90%;
}

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph.p-1 {
	margin-top: 10px;
}

#how-it-works-franch .como-funciona-franchisee-cards .info-paragraph.p-2 {
	margin-bottom: 30px;
}

/* Button Orçamento */

#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
	background: #FFAA49;
	color: #ffffff;
}

/* Section Orçamento */

#how-it-works-franch .como-funciona-franchisee-cards .section-orcamento {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

#how-it-works-franch .return-text {
	text-align: center;
}

/* Media Querys */

@media screen and (max-width: 600px) {

	/* Como Funciona */
	.como-funciona-franchisee-cards {
		text-align: center;
	}

	#how-it-works-franch .como-funciona-franchisee-cards h2.ui.header {
		font-size: 2em;
		text-align: center;
		padding: 0 50px;
	}
}

@media screen and (min-width: 440px) {

	/* Button Orçamento */
	#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
		font-size: 1.7rem;
		margin-top: 25px;
	}
}

@media screen and (min-width: 390px) and (max-width: 439px) {

	/* Button Orçamento */
	#how-it-works-franch .como-funciona-franchisee-cards .button-cards {
		font-size: 1.3rem;
	}
}
