.slide-enter,
.slide-exit {
  opacity: 0.4;
  transition: transform 500ms ease-in-out;
}

.slide-enter {
  opacity: 1;
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  opacity: ;
  position: absolute;
  top: calc(100% - 90%);
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  opacity: 0.5;
  transform: translateX(-100%);
}
