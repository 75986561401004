@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700");
@import "./transitions/Slide.css";

body {
  margin: 0;
  margin-bottom: 5rem;
  background: #f0f0f0;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
}

.ReactModal__Overlay {
  transform: translateY(calc(100% - 10px));
  transition: all 350ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  transform: translateY(calc(100% - 10px));
}