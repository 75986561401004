/* Header */

.header-top {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1200px;
}

#header-top .button-entrar-franch button {
	background: #393285;
	color: #ffffff;
}

/* Menu Mobile */

#header-top .MuiBottomNavigation-root {
	background-color: #F2F2F2;
}

.MuiToolbar-root {
	display: flex;
	justify-content: space-between;
}

#header-top .MuiAppBar-colorPrimary {
	color: #fff;
	background-color: #ffffff;
}

#header-top .makeStyles-root-1 {
	width: 100%;
	align-items: center;
}

#header-top .jss1 {
	width: 100%;
}

.mobile-menu {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 999999;
}

/* Menu */

#header-top .ui.button {
	text-transform: uppercase;
}

/* Logo */

.logo-franchisee-link {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Media Querys */

@media screen and (min-width: 600px) {
	/* Menu */
	.button-menu:before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: #393285;
		transform: scaleX(0);
	}
	.button-menu:hover:before, .button-menu:focus:before {
		transform: scaleX(.85);
	}
	.button-menu:before, .button-menu:after {
		content: "";
		position: absolute;
		transition: transform 0.5s ease;
	}
	button:before {
		content: none;
	}
	a, a>span {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
	}
}

@media screen and (max-width: 465px) {
	/* Logo */
	.logo-franchisee {
		display: flex;
	}
	.logo-franchisee img {
		width: 170px;
	}
	#header-top .button-entrar-franch button {
		font-size: 10px;
	}
	#header-top .button-orcamento button {
		font-size: 10px;
	}
}

@media screen and (max-width: 340px) {
	/* Logo */
	.logo-franchisee {
		display: flex;
	}
	.logo-franchisee img {
		width: 150px;
	}
	#header-top .button-entrar-franch button {
		font-size: 8px;
		display: flex;
	}
	#header-top .button-orcamento button {
		font-size: 8px;
		display: flex;
	}
}


@media screen and (max-width: 605px) {
	#header-top .MuiButton-root {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
		font-size: 8px;
    padding-right: 10px;
    padding-left: 10px;
		text-align: center;
	}
}
@media screen and (max-width: 475px) {
	#header-top .MuiButton-root {
		position: relative;
		color: inherit;
		text-decoration: none;
		line-height: 24px;
    padding-right: 10px;
    padding-left: 10px;
		text-align: center;
	}
}

@media screen and (max-width: 600px) {
	.logo-franchisee img {
		width: 150px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 12px;
	}
	#header-top .button-orcamento button {
		font-size: 12px;
	}
}

@media screen and (max-width: 465px) {
	.logo-franchisee img {
		width: 130px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 9px;
	}
	#header-top .button-orcamento button {
		font-size: 9px;
	}
}

@media screen and (max-width: 375px) {
	.logo-franchisee img {
		width: 110px;
		padding-right: 20px;
	}
	#header-top .button-entrar-franch button {
		font-size: 8px;
	}
	#header-top .button-orcamento button {
		font-size: 8px;
	}
}