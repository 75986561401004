/* Banner */

.banner {
    background-image: url("../../../../images/Banner/reparando-smartphone-dark.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: center center;
    content: "";
    text-align: left;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
}

.banner-container {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.5rem;
    padding-right: 0.5rem;
}

.banner-container .text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#home .banner-container .MuiTypography-h1 {
    font-size: 35px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home .banner-container .MuiTypography-h2 {
    font-size: 18px;
    margin-top: 10px;
    text-shadow: 0px 0px 20px rgb(0 0 0);
}

#home .banner-container .button-banner {
    background: #FFAA49;
    color: #ffffff;
    margin-top: 10px;
}

/* Media Querys */

@media screen and (min-width: 600px) and (max-width: 800px) {
    /* Banner */
    #home .banner-container .MuiTypography-h1  {
        font-size: 30px;
        width: 90%;
    }
}

@media screen and (min-width: 440px) {
    /* Banner */
    #home .banner-container .button-banner {
        font-size: 1.7rem;
        margin-top: 25px;
    }

    /* Título */
    #home .banner-container .MuiTypography-h1 {
        width: 70%;
    }
}

@media screen and (min-width: 390px) and (max-width: 439px) {
    /* Banner */
    #home .banner-container .button-banner {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 600px) {
    /* Banner */
    #home .banner-container .MuiTypography-h1  {
        font-size: 26px;
        width: 100%;
        text-align: center;
    }
    #home .banner-container .MuiTypography-h2 {
        font-size: 16px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .banner-container {
        text-align: center;
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .banner {
        margin-top: 30px;
    }
}