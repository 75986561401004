.servicos {
    padding: 70px 0;
    background: #F2F2F2;
}

.servicos-interno {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

/* Lista */

#services .servicos-interno .MuiListItem-root {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lista-servicos {
    margin: 10px 10px;
    width: 22%;
    border: 1px solid #ccc;
    border-radius: 20px;
}

.root {
    color: #FFAA49;
    background-color: #ffffff;
    border-radius: 20px;
    min-height: 178px;
}

/* Titulo */

.titulo {
    width: 100%
}

#services .servicos-interno .titulo h2 {
    font-family: 'Roboto';
    font-size: 3em;
    font-weight: 100;
}

/* Texto Lista */

#services .servicos-interno .MuiTypography-colorTextSecondary {
    color: #3C3C3C;
    padding-top: 10px;
}

/* Título Lista */

#services .servicos-interno .MuiTypography-body1 {
    font-weight: 600;
    text-transform: uppercase;
}

.inline {
    display: 'inline';
}

/* Ícone */

#services .servicos-interno .MuiAvatar-root {
    border-radius: 0;
}

.MuiListItemAvatar-alignItemsFlexStart {
    justify-content: center;
    align-items: center;
    display: flex;
}

/* Button Orçamento */

#services .servicos-interno .section-orcamento .button-services {
    background: #FFAA49;
    color: #ffffff;
}

/* Section Orçamento */

#services .servicos-interno .section-orcamento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

#services .servicos-interno .MuiDivider-root {
    background-color: #3C3C3C;
}

.MuiListItem-gutters {
    min-height: 158px;
}

@media screen and (min-width: 800px) {
    #services .servicos-interno .titulo h2 {
        padding: 0 0 30px 40px;
    }
}

@media screen and (max-width: 800px) {
    .lista-servicos {
        width: 28%;
    }
    #services .servicos-interno .titulo h2 {
        text-align: center;
        padding-bottom: 30px;
        font-size: 2em;
    }
}

@media screen and (max-width: 550px) {
    .lista-servicos {
        width: 40%;
    }
}

@media screen and (max-width: 430px) {
    .lista-servicos {
        width: 80%;
    }
}

@media screen and (min-width: 440px) {
    /* Button Orçamento */
    #services .servicos-interno .section-orcamento .button-services {
        font-size: 1.7rem;
        margin-top: 25px;
    }
}

@media screen and (min-width: 390px) and (max-width: 439px) {
    /* Button Orçamento */
    #services .servicos-interno .section-orcamento .button-services {
        font-size: 1.3rem;
    }
}